<template>
    <div>
        <div class="SecondTitleRegistration" style="display: flex;justify-content: center;">
            <span>{{ $t('registration.registrationStore') }} : {{ this.storeName }}</span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form>

                <van-field required :error-message="errMessageSalutation">
                    <template #input>
                        <van-dropdown-menu style="width: 100%;">
                            <van-dropdown-item :title="genderOptions.find(i => i.value == tmpTitle)?.text || $t('registration.salutation')" v-model="tmpTitle" :options="genderOptions" />
                        </van-dropdown-menu>
                    </template>
                </van-field>

                <van-field 
                    required 
                    v-model="info.localFirstName" 
                    :placeholder="this.$t('registration.firstName')"
                    :error-message="errMessageFirstName" 
                    @input="validatorFirstName()"
                    @focus="validatorSalutation()"
                />

                <van-field 
                    required 
                    v-model="info.localLastName" 
                    :placeholder="this.$t('registration.lastName')"
                    :error-message="errMessageLastName" 
                    @input="validatorLastName()"
                    @focus="validatorFirstName(),validatorSalutation()"
                />
                
                <van-field 
                    required
                    label="+84"
                    label-width="30"
                    type="number"
                    maxlength="10"
                    :placeholder="this.$t('registration.mobileNumber')"
                    v-model="info.mobile"
                    :error-message="errMessagePhone"
                    @input="validatorPhone()"
                    @focus="validatorFirstName(),validatorLastName(),validatorSalutation()"
                />

                <van-field 
                    v-model="info.email" 
                    :placeholder="this.$t('registration.email')"
                    :error-message="errMessageEmail"
                    @input="validatorEmail()"
                    @focus="validatorFirstName(),validatorLastName(),validatorPhone(),validatorSalutation()"
                />

                <van-field
                    :label="this.$t('registration.formValueDateOfBirth')"
                    :error-message="errMessageBirthMonth"
                    label-width="90"
                    input-align="right"
                >
                    <template #input>
                        <BirthView class="birth-view"
                            :birth="info.birth" country="VN" :requiredMonth=true
                            @change="validatorBirthMonth(), validatorFirstName(), validatorLastName(), validatorPhone(),validatorSalutation()"
                        />
                    </template>
                </van-field>

                <SearchAddressView
                    v-model="info.city"
                    :columns="addresses"
                    valueKey="value"
                    labelKey="text"
                    :placeholder="this.$t('registration.city')"
                    :readonly="false"
                />

                <div class="second-tips">
                    <div>
                        <input 
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.privacyPolicyAgree"
                            @change="validatorPersonalInfo(),validatorFirstName(),validatorLastName(),validatorPhone(),validatorBirthMonth(),validatorSalutation()"
                        />
                    </div>
                    <div class="agreeStatementMandatory" v-html='$t("registration.pInfoAgree").format(this.brandConfig.display,privacyPolicyLink)'></div>
                </div>

                <div style="color:#ff0000;margin-left: 45px;">
                    {{ this.errMessagePersonalInfo }}
                </div>

                <div class="second-tips">
                    <div >
                        <input 
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.marketingAgree"
                            @change="validatorPersonalInfo(),validatorFirstName(),validatorLastName(),validatorPhone(),validatorBirthMonth(),validatorSalutation()"
                        />
                    </div>
                    <div class="agreeStatement" v-html='promotionText'></div>
                </div>

                <div style="margin-top: 20px">
                    <van-button
                        :disabled="submitDisabled"
                        size="large"
                        round
                        class="button-submit"
                        @click="submit()"
                        :loading="loading"
                    >{{ $t("common.submit") }}</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import {
    Button,
    Cell,
    CellGroup,
    DropdownItem,
    DropdownMenu,
    Field,
    Checkbox,
    CheckboxGroup,
    Form,
    Dialog,
    Toast,
} from "vant";
import { getAddress, getSession, relateAddOrUpdate } from "@/api/eformApi";
import mixins from "./../mixin/Registration.js";
export default {
    name: "Registration",
    mixins: [mixins],
    components: {
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
        BirthView: () => import("./../components/BirthView"),
        SearchAddressView: () => import("./../components/SearchAddressView"),
    },
    data() {
        return {
            info: {
                localFirstName: "",
                localLastName: "",
                email: "",
                mobile: "",
                birth: {
                    day: "",
                    month: "",
                    year: "",
                },
                title: "",
                city: "",
                privacyPolicyAgree: false,
                marketingAgree: false,
            },
            loading: false,
            errMessageSalutation: "",
            errMessageFirstName: "",
            errMessageLastName: "",
            errMessagePhone: "",
            errMessageEmail: "",
            errMessageBirthMonth: "",
            errMessagePersonalInfo: "",
            specialCharsRegex:  /[`:._~!@#$%^&*() \\+ =<>?"{}|, \\/ ;' \\ [ \] ·~！@#￥%……&*（）—— \\+ ={}|《》？：“”【】、；‘’，。、-]/g,
            emailRegex: /^[0-9a-zA-Z-_.%+]+@[0-9a-zA-Z-_.%+]+[.][0-9a-zA-Z]{1,10}$/,
            nameRegex: /^[a-zA-ZÀ-ỹ]+$/u,
            addresses: [],
            addressesEN: [],
            addressesVN: [],
            genderOptions: [
                { text: this.$t('registration.mrs'), value: "Mrs" },
                { text: this.$t('registration.ms'), value: "Ms" },
                { text: this.$t('registration.dr'), value: 'Dr' },
                { text: this.$t('registration.mr'), value: "Mr" },
                { text: this.$t('registration.othe'), value: "Other" }
            ],
            tmpTitle: "",
            titleMap: {
                Mrs: {
                    en: "Mrs",
                    vn: "Cô",
                },
                Ms: {
                    en: "Ms",
                    vn: "Chị",
                },
                Dr: {
                    en: "Dr",
                    vn: "Ông",
                },
                Mr: {
                    en: "Mr",
                    vn: "Anh",
                },
                Other: {
                    en: "Other",
                    vn: "Khác",
                },
            }
        };
    },
    
    created() { 
        this.getAddress()
    },
    computed: {
        privacyPolicyLink() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a style="color: blue" href="${this.brandConfig.privacyPolicy}">${this.$t("common.privacyPolicy")}</a></span>`;
            } else {
                return `<span>${this.$t("common.privacyPolicy")}</span>`;    
            }
        },
        promotionText() {
            const brand = this.$route.params.brand
            if (brand == 'EL' || brand == 'CL') {
                return `<span>${this.$t("registration.promotionAgree")}</span><br/>
                <span>${this.$t("registration.promotionAgree2")}</span>`;
            }
            return `<span>${this.$t("registration.promotionAgree")}</span><br/>`
        },
        currentLocale() {
            return this.$root.$i18n.locale;
        },
        submitDisabled() { 
            return !this.info.privacyPolicyAgree || this.errMessageFirstName != "" || this.errMessageLastName != "" ||
                this.errMessagePhone != "" || this.errMessageEmail != "" || this.errMessageBirthMonth != "" || this.errMessagePersonalInfo != ""
        },
    },
    watch: {
        tmpTitle:{
            handler() {
                this.validatorSalutation()
            }
        },
        currentLocale: {
            handler() {
                if (this.errMessageSalutation != "") {
                    this.validatorSalutation();
                }
                if (this.errMessageFirstName != "") {
                    this.validatorFirstName();
                }
                if (this.errMessageLastName != "") {
                    this.validatorLastName();
                }
                if (this.errMessagePhone != "") {
                    this.validatorPhone();
                }
                if (this.errMessageEmail != "") {
                    this.validatorEmail();
                }
                if (this.errMessageBirthMonth != "") {
                    this.validatorBirthMonth();
                }
                if (this.errMessagePersonalInfo != "") { 
                    this.validatorPersonalInfo();
                }
                this.genderOptions = [
                    { text: this.$t('registration.mrs'), value: "Mrs" },
                    { text: this.$t('registration.ms'), value: "Ms" },
                    { text: this.$t('registration.dr'), value: 'Dr' },
                    { text: this.$t('registration.mr'), value: "Mr" },
                    { text: this.$t('registration.othe'), value: "Other" }
                ]
                if (this.currentLocale == 'vn') {
                    this.addresses = this.addressesVN
                }
                if (this.currentLocale == 'en') {
                    this.addresses = this.addressesEN
                }
            },
        },
    },
    methods: {
        validatorSalutation() {
            this.errMessageSalutation = ''
            if (this.tmpTitle == '' || this.tmpTitle.trim() == '') {
                this.errMessageSalutation = this.$t('registration.errMsgGender');
                return false;
            }
            return true
        },
        validatorFirstName() {
            this.errMessageFirstName = ""
            this.info.localFirstName = this.info.localFirstName.replace(this.specialCharsRegex, "")
            if (this.info.localFirstName.trim() == "") {
                this.errMessageFirstName = this.$t("registration.errMessageFirstName");
                return false;
            }
            if (!this.nameRegex.test(this.info.localFirstName.trim())) {
                this.errMessageFirstName = this.$t("registration.errMessageFirstNameInvalid");
                return false;
            }
            return true;
        },
        validatorLastName() {
            this.errMessageLastName = ""
            this.info.localLastName = this.info.localLastName.replace(this.specialCharsRegex, "")
            if (this.info.localLastName.trim() == "") {
                this.errMessageLastName = this.$t("registration.errMessageLastName");
                return false;
            }
            if (!this.nameRegex.test(this.info.localLastName.trim())) {
                this.errMessageLastName = this.$t("registration.errMessageLastNameInvalid");
                return false;
            }
            return true;
        },
        validatorEmail() {
            this.errMessageEmail = "";
            if (!this.info.email.trim()) {
                return true
            }
            if (!this.emailRegex.test(this.info.email.trim())) {
                this.errMessageEmail = this.$t("registration.errMessageEmail");
                return false;
            }
            return true;
        },
        validatorPhone() {
            this.errMessagePhone = "";
            if (this.info.mobile.trim() == "") {
                this.errMessagePhone = this.$t("registration.errMessagePhone");
                return false;
            }
            if (this.info.mobile.trim().length < 10) {
                this.errMessagePhone = this.$t("registration.errMessagePhoneInvalid");
                return false;
            }
            return true;
        },
        validatorBirthMonth() {
            this.errMessageBirthMonth = ""
            if (this.info.birth.month == "") {
                this.errMessageBirthMonth = this.$t("registration.errMessageBirthMonth");
                return false;
            } 
            return true;
        },
        validatorPersonalInfo() {
            this.errMessagePersonalInfo = "";
            if (!this.info.privacyPolicyAgree) {
                this.errMessagePersonalInfo = this.$t("registration.errMessagePersonalInfo");
                return false;
            }
            return true;
        },
        getAddress() {
            getAddress({
                country: this.$route.params.countryPath,
            }).then(res => {
                if (res.success) {
                    this.addressesVN = res.data.citiesVN
                    this.addressesEN = res.data.citiesEN
                    if (this.$route.params.lang == 'vn') {
                        this.addresses = this.addressesVN
                    }
                    if (this.$route.params.lang == 'en') {
                        this.addresses = this.addressesEN
                    }
                }
            })
        },
        submit() {
            this.loading = true;
            if (
                !this.validatorFirstName() | !this.validatorLastName() |
                !this.validatorPhone() | !this.validatorEmail() |
                !this.validatorBirthMonth() | !this.validatorPersonalInfo() |
                !this.validatorSalutation()
            ) {
                this.loading = false;
                return;
            }

            this.info.localFirstName = this.info.localFirstName.trim()
            this.info.localLastName = this.info.localLastName.trim()
            this.info.email = this.info.email.trim()
            this.info.mobile = this.info.mobile.trim()
            this.info.title = this.titleMap[this.tmpTitle][this.$root.$i18n.locale]

            this.doSubmit()
        },
        doSubmit() {
            getSession({
                ...this.info,
                country: this.$route.params.countryPath,
                brand: this.$route.params.brand,
            }).then(res => {
                if (res.success) {
                    this.doAddConsumer(res.data)
                }else {
                    Toast.fail({
                        type: "fail",
                        className: "noticeWidth",
                        message: this.$t('common.errorVN'),
                    });
                    this.loading = false;
                }
            }).catch(() => {
                Toast.fail({
                    type: "fail",
                    className: "noticeWidth",
                    message: this.$t('common.errorVN'),
                });
                this.loading = false;
            })
        },
        doAddConsumer(sessionKey) {
            relateAddOrUpdate({
                ...this.info,
                preLanguage: this.$root.$i18n.locale,
                country: this.$route.params.countryPath,
                storeId: this.storeId,
                storeName: this.storeName,
                brand: this.$route.params.brand,
                sessionKey: sessionKey,
                txNo: this.txNo
            }).then((res) => {
                this.loading = false;
                if (res.success) {
                    this.$router.push({
                        name: 'QRcodes',
                        params: {
                            custId: res.data.frontiersVipNo,
                            storeId: this.storeId,
                            lang: this.$route.params.lang,
                            brand: this.$route.params.brand,
                            country: this.$route.params.countryPath,
                            txNo: this.txNo
                        },
                        query: {
                            storeId: this.$route.query.storeId,
                        }
                    })
                } else if (res.data.exist) {
                    Dialog.alert({
                        message: this.$t('registration.alreadyRegistrationVN'),
                        confirmButtonText: this.$t('common.confirm')
                    })
                    return;
                } else {
                    Toast.fail({
                        type: "fail",
                        className: "noticeWidth",
                        message: this.$t('common.errorVN'),
                    });
                }
            }).catch(() => {
                Toast.fail({
                    type: "fail",
                    className: "noticeWidth",
                    message: this.$t('common.errorVN'),
                });
                this.loading = false;
            })
        },
    },
};
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

.phone_class {
    display: flex;
    justify-content: space-between;
}

.phone_class >>> .van-field__control {
    border-bottom: 1px solid lightgray;
    text-align: center;
}

.phone_class >>> .van-field {
    padding: unset;
}

input.agreeCheckBox {
    transform: scale(1.5);
    margin-top: 33%;
}

.agreeStatement {
    margin-left: 10px;
    margin-right: 24px;
}

.agreeStatementMandatory {
    margin-left: 10px;
    margin-right: 24px;
}

.agreeStatementMandatory::before {
    padding-right: 2px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
    z-index: 100;
}

.desc-tips-agreeStatement {
    font-size: 15px !important;
}

.second-tips {
    display: inline-flex;
    font-size: 14px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 15px;
    margin-left: 15px;
    line-height: 20px;
    width: 100%;
}

.desc-tips {
    display: inline-flex;
    font-family: Helvetica-Regular;
    font-weight: 400;
    line-height: 20px;
    width: 90%;
    padding-left: 30px;
}

.errMsg-birth {
    color:#ff0000;
    text-align: right;
    padding-right: 15px;
}

.second-no-checkbox-tips {
    margin-left: -10px;
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 10px;
    line-height: 20px;
    width: 100%;
}

.second-follow-tips {
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 5px;
    line-height: 20px;
    width: 100%;
}

.third-tips {
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 5px;
    line-height: 30px;
    width: 100%;
    margin-left: 30px;
}

.button-submit {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
}

>>> .van-dropdown-menu {
    width: 100%;
}

>>> .van-dropdown-menu__title {
    font-size: 14px;
    padding-left: unset;
}

>>> .van-dropdown-menu__item {
    justify-content: left;
}

>>> .van-dropdown-menu__bar {
    height: unset;
    box-shadow: unset;
}

>>> .van-dialog {
    border-radius: 5px;
}

>>> .birth-view .van-dropdown-menu__item {
    justify-content: center;
}

>>> .van-field__label {
    color:#262626
}
</style>
<style>
.noticeWidth {
    width: auto;
}
</style>
